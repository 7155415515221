<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.29 18.2514C13.837 18.2514 17.965 11.9984 17.965 6.57645C17.965 6.39845 17.965 6.22145 17.953 6.04644C18.7562 5.46497 19.4493 4.74506 20 3.92045C19.2511 4.25245 18.4566 4.47021 17.643 4.56645C18.4996 4.0535 19.1408 3.24679 19.447 2.29645C18.6417 2.77433 17.7607 3.11117 16.842 3.29245C16.2234 2.63421 15.405 2.19828 14.5136 2.05215C13.6222 1.90602 12.7075 2.05784 11.9111 2.48411C11.1147 2.91038 10.4811 3.58732 10.1083 4.41011C9.7355 5.23289 9.64437 6.15563 9.849 7.03544C8.21759 6.95369 6.6216 6.52977 5.16465 5.79121C3.70769 5.05266 2.42233 4.01597 1.392 2.74844C0.867318 3.65165 0.706589 4.72087 0.942536 5.73841C1.17848 6.75595 1.79337 7.64532 2.662 8.22545C2.00926 8.20651 1.37065 8.03091 0.8 7.71344V7.76544C0.800389 8.71274 1.1284 9.63075 1.7284 10.3638C2.3284 11.0969 3.16347 11.5998 4.092 11.7874C3.48781 11.9522 2.85389 11.9761 2.239 11.8574C2.50116 12.6729 3.01168 13.386 3.69913 13.8971C4.38658 14.4081 5.21657 14.6914 6.073 14.7074C5.22212 15.376 4.24779 15.8703 3.20573 16.162C2.16367 16.4537 1.07432 16.5371 0 16.4074C1.8766 17.6118 4.06019 18.2505 6.29 18.2474"
      fill="#9CA3AF"
    />
  </svg>
</template>