import axios from 'axios';

const app_url = process.env.VUE_APP_FUNCTIONS_URL;


const getCollections = async() => {
    const result = await axios.get(app_url+'find_collections');
    return result.data;
}

const getCollection = async(id) => {
    const result = await axios.get(app_url+'find_collection?ref='+id);
    return result.data;
}


export {
    getCollections,
    getCollection
};