<template>
    
<div class="w-full">
    <div class="inline-flex items-center justify-center w-full">
        
    </div>
    
    <div class="page-wrapper">
    <div class="section-timeline bg-gradient-to-b from-black to-primary-900">
        <div class="container">
        <div class="timeline_component">
            <div class="timeline_progress">
            <div class="timeline_progress-bar" v-show="show"></div>
            </div>
            <div
            data-w-id="d5abcf1f-3370-3eea-ccfd-66f076babfe0"
            class="timeline_item"
            >
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe1-5d47b428"
                class="timeline_left"
            >
                <div class="timeline_date-text">
                <div class="text-primary-500">Phase 1</div>
                <div class="text-white text-xl">
                  NFTs + Layer 3 Phase 1
                </div>
                <div class="text-primary-100 text-lg">Oct - Nov 2022</div>
                </div>
            </div>
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe4-5d47b428"
                class="timeline_centre"
            >
                <div class="timeline_circle"></div>
            </div>
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe6-5d47b428"
                class="timeline_right"
            >
                <div class="margin-bottom-xlarge">
                <div class="timeline_text">
                    <ul class="space-y-6">
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Release of NFT Seed Collection and in-house NFT marketplace</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Launch of Phase 1 of Layer 3 </span></li>
                    </ul>
                    
                </div>
                </div>
                
            </div>
            </div>
            <div
            data-w-id="d5abcf1f-3370-3eea-ccfd-66f076babfe0"
            class="timeline_item"
            >
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe1-5d47b428"
                class="timeline_left"
            >
                <div class="timeline_date-text">
                <div class="text-primary-500">Phase 2</div>
                <div class="text-white text-xl">
                    $ROCKET
                </div>
                <div class="text-primary-100 text-lg">Q4 2022</div>
                </div>
            </div>
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe4-5d47b428"
                class="timeline_centre"
            >
                <div class="timeline_circle"></div>
            </div>
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe6-5d47b428"
                class="timeline_right"
            >
                <div class="margin-bottom-xlarge">
                <div class="timeline_text">
                    <ul class="space-y-6">
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Launch of $ROCKET - provides liquidity for rewards</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Begin compounding governance NFTs into Land NFTs</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Start managing tokenomics for your planet</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Compete with other planets for the best yield</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Begin governance voting for your planet</span></li>
                    </ul>
                    
                </div>
                </div>
                
            </div>
            </div>
            <div
            data-w-id="d5abcf1f-3370-3eea-ccfd-66f076babfe0"
            class="timeline_item"
            >
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe1-5d47b428"
                class="timeline_left"
            >
                <div class="timeline_date-text">
                <div class="text-primary-500">Phase 3</div>
                <div class="text-white text-xl">
                    $VERSE
                </div>
                <div class="text-primary-100 text-lg">Q1  2023</div>
                </div>
            </div>
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe4-5d47b428"
                class="timeline_centre"
            >
                <div class="timeline_circle"></div>
            </div>
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe6-5d47b428"
                class="timeline_right"
            >
                <div class="margin-bottom-xlarge">
                <div class="timeline_text">
                    <ul class="space-y-6">
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Launch of $VERSE</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Implement your own DeFi protocol without code</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Vote on location of your planet’s first settlement</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Issuance of land NFTs with utility in the metaverse</span></li>
                    </ul>
                    
                </div>
                </div>
                
            </div>
            </div>
            <div
            data-w-id="d5abcf1f-3370-3eea-ccfd-66f076babfe0"
            class="timeline_item"
            >
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe1-5d47b428"
                class="timeline_left"
            >
                <div class="timeline_date-text">
                <div class="text-primary-500">Phase 4</div>
                <div class="text-white text-xl">
                    MetaVerse
                </div>
                <div class="text-primary-100 text-lg">Q3  2023</div>
                </div>
            </div>
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe4-5d47b428"
                class="timeline_centre"
            >
                <div class="timeline_circle"></div>
            </div>
            <div
                id="w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe6-5d47b428"
                class="timeline_right"
            >
                <div class="margin-bottom-xlarge">
                <div class="timeline_text">
                    <ul class="space-y-6">
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Launch of our space metaverse with 3D interface</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Governance voting on your planet’s main industry</span></li>
                        <li class="flex items-center"><colletiverse-list /> <span class="ml-2 flex-shrink w-3/4">Use your land NFTs to build your virtual space world</span></li>
                    </ul>
                    
                </div>
                </div>
                
            </div>
            </div>
            
        </div>
        </div>
    </div>
    
    </div>
</div>
</template>
<script>
import { onMounted } from '@vue/runtime-core';
import ColletiverseList from '../../components/Icons/ColletiverseList.vue';
export default {
  components: { ColletiverseList },
  setup() {
    const goToLP = () => {
      window.open("https://collectiverse.gitbook.io/collectiverse/");
    };

    onMounted(() => {
      
    })

    return {
      goToLP,
    };
  },
};
</script>
<style scoped>

p {
  margin-bottom: 0px;
}

.page-wrapper {
  position: relative;
  z-index: 0;
}

.section-hero {
  position: relative;
  display: none;
}

.container {
  width: 90vw;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
}

.padding-vertical-xxlarge {
  padding-top: 160px;
  padding-bottom: 160px;
}

.timeline-hero_heading-wrapper {
  position: relative;
  display: none;
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.background-layer {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  min-height: 500px;
  background-color: #d8d5d1;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  -o-object-fit: fill;
  object-fit: fill;
}

.text-underline {
  display: inline-block;
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-position: 50% 110%;
  background-size: contain;
  background-repeat: no-repeat;
}

.margin-bottom-medium {
  margin-bottom: 32px;
}

.paragraph-large {
  font-size: 20px;
  letter-spacing: -0.02em;
}

.section-timeline-heading {
  background-color: #108181;
}

.padding-vertical-xlarge {
  padding-top: 120px;
  padding-bottom: 120px;
}

.timeline-main_heading-wrapper {
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  text-align: center;
}

.timeline_component {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1120px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.timeline_item {
  position: relative;
  z-index: 2;
  display: -ms-grid;
  display: grid;
  padding-top: 80px;
  padding-bottom: 80px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 180px 1fr;
  grid-template-columns: 1fr 180px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.timeline_left {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: right;
}

.timeline_centre {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.timeline_date-text {
  position: -webkit-sticky;
  position: sticky;
  top: 50vh;
  font-size: 30px;
  line-height: 1.2;
  font-weight: bold;
}

.timeline_text {
  color: #fff;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 500;
}

.timeline_circle {
  position: -webkit-sticky;
  position: sticky;
  top: 50vh;
  width: 15px;
  height: 15px;
  max-height: 15px;
  max-width: 15px;
  min-height: 15px;
  min-width: 15px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 8px #108181;
}

.timeline_progress {
  position: absolute;
  z-index: -2;
  width: 3px;
  height: 100%;
  background-color: #5fffff;
}

.timeline_progress-bar {
  position: fixed;
  left: auto;
  top: 0px;
  right: auto;
  bottom: 50vh;
  z-index: -1;
  width: 3px;
  height: 50vh;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ff7448),
    color-stop(51%, #ff4848),
    to(#6248ff)
  );
  background-image: linear-gradient(180deg, #ff7448, #ff4848 51%, #6248ff);
}

.section-timeline {
  position: relative;
  z-index: -3;
  
}

.section-timeline-cta {
  background-color: #108181;
}

.margin-bottom-xlarge {
  margin-bottom: 56px;
}

.timeline_image-wrapper {
  overflow: hidden;
  border-radius: 12px;
  background-image: linear-gradient(138deg, #000, transparent 28%);
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
}

.timeline_link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.6;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
}

.timeline_link:hover {
  opacity: 1;
}

.link-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.inline-block {
  display: inline-block;
}

.text-colour-lightgrey {
  color: hsla(0, 0%, 100%, 0.65);
}

.timeline_quote-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 16px;
}

.timeline_quote-image {
  width: 48px;
  height: 48px;
  margin-right: 24px;
  border-radius: 8px;
}

.timeline_quote-text-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.timeline_quote {
  margin-bottom: 16px;
  color: #fff;
  font-size: 14px;
}

.timeline_quote-title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.timeline_badge {
  display: inline-block;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.timeline_definition-wrapper {
  padding: 16px;
  border-radius: 16px;
  background-color: hsla(0, 0%, 100%, 0.05);
}

.text-colour-white {
  color: #fff;
}

.timeline_milestone-badge {
  display: inline-block;
  margin-bottom: 24px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #fff;
  color: #161616;
  font-size: 14px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.button-inverted {
  padding: 14px 32px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 8px;
  background-color: #fff;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #161616;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.button-inverted:hover {
  -webkit-transform: translate(0px, -4px);
  -ms-transform: translate(0px, -4px);
  transform: translate(0px, -4px);
}

.overlay-fade-top {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 80px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0a0a0a),
    to(rgba(10, 10, 10, 0))
  );
  background-image: linear-gradient(180deg, #0a0a0a, rgba(10, 10, 10, 0));
}

.overlay-fade-bottom {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 80px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#0a0a0a),
    to(rgba(10, 10, 10, 0))
  );
  background-image: linear-gradient(0deg, #0a0a0a, rgba(10, 10, 10, 0));
}

.margin-bottom-small {
  margin-bottom: 24px;
}

.button-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.button-icon-small {
  z-index: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 20px 12px 18px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #108181;
  border-radius: 8px;
  background-color: #108181;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
}

.button-icon-small:hover {
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
}

.button-icon-small.margin-right-small {
  margin-right: 16px;
}

.margin-bottom-large {
  margin-bottom: 40px;
}

.button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button-icon-small-secondary {
  z-index: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 20px 12px 18px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #0a0a0a;
  border-radius: 8px;
  background-color: #fff;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: #161616;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.8px;
  text-decoration: none;
  text-transform: uppercase;
}

.button-icon-small-secondary:hover {
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
}

.button-icon-small-secondary.margin-right-small {
  margin-right: 16px;
}

.section-footer {
  position: relative;
  display: none;
}

.footer_text-wrapper {
  position: relative;
  max-width: 640px;
  margin-right: auto;
  margin-bottom: 120px;
  margin-left: auto;
  text-align: center;
}

.footer_credits-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-link {
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: underline;
}

.padding-vertical-footer {
  padding-top: 120px;
  padding-bottom: 40px;
}

.hero-nav {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90vw;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.relume-link {
  position: relative;
  width: 124px;
  height: 33.33px;
}

.relume-logo-primary {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  color: #000;
}

.relume-logo-secondary {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  color: #fff;
}

.hero-nav_button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.parallax {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block {
  width: 100%;
  height: 500px;
  background-position: 50% 50%;
  background-size: auto;
  background-attachment: scroll;
}

.skills {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.content__container {
  position: static;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 1120px;
  padding: 64px 32px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: transparent;
  font-size: 1rem;
}

.button__magnetic-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 64px;
  padding: 0px 64px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #531431;
  color: #f2e5d9;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.tag {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #531431;
  color: #e4b3a3;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.tag:hover {
  background-color: #702d4c;
  color: #f2e5d9;
}

.bottom02 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 3;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.bottom03 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.footer__bottom {
  color: #e4b3a3;
  font-size: 14px;
}

.quotes {
  width: 100%;
  height: auto;
  margin-top: 64px;
  grid-auto-flow: row;
  grid-column-gap: 96px;
  grid-row-gap: 64px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 0.9rem;
}

.link {
  color: #e4b3a3;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.cloud03 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.footer {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 720px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #020016;
  text-align: center;
}

.social__icon-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ececec;
  font-size: 1.2rem;
  line-height: 1;
}

.top04 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 37;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.quote__paragraph {
  color: #a07769;
  font-size: 1rem;
  line-height: 1.4;
  font-style: italic;
  font-weight: 400;
}

.social-icon {
  position: relative;
  z-index: 3;
  color: #e4b3a3;
}

.cloud06 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 7;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.cloud08 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 5;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.cloud04 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.cloud05 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 8;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.about {
  width: 100%;
  height: auto;
  margin-top: 64px;
  margin-bottom: 32px;
  grid-column-gap: 96px;
  grid-row-gap: 64px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.bg-hero {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
  min-width: 100%;
  margin-top: -288px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.social {
  position: static;
  z-index: 6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.quote__txt-name {
  margin-top: 24px;
  color: #a07769;
  font-weight: 600;
}

.section {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cloud02 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 11;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.top03 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 38;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.content {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding-bottom: 128px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#2f122e),
    to(#020016)
  );
  background-image: linear-gradient(180deg, #2f122e, #020016);
}

.footer__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 1920px;
  min-height: 720px;
  padding-top: 0px;
  padding-bottom: 64px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-position: 50% 0%;
  background-size: cover;
  text-align: center;
}

.social__icon-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  color: #ececec;
  text-decoration: none;
}

.footer__contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cloud01 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 12;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.cloud07 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 6;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.top02 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 39;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.footer__paragraph {
  margin-bottom: 32px;
  background-size: cover;
  background-attachment: scroll;
  color: #e4b3a3;
  font-size: 3.5rem;
  font-weight: 600;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quote__txt-fonction {
  color: #a07769;
  font-size: 0.8rem;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
}

.button__magnetic-link {
  position: relative;
  z-index: 100;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #333;
  text-decoration: none;
}

.button__magnetic-link.default {
  border-style: none;
  border-width: 0px;
  border-radius: 32px;
}

.social__bg {
  position: absolute;
  z-index: 1;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #531431;
}

.button__magnetic {
  position: relative;
}

.button__magnetic.ghost1 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 90;
  margin: 1px;
  border-radius: 32px;
  background-color: #d26066;
  opacity: 0.5;
}

.button__magnetic.ghost2 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 80;
  margin: 1px;
  border-radius: 32px;
  background-color: #d26066;
  opacity: 0.5;
}

.button__magnetic.ghosting {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 64px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button__magnetic.ghost3 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 70;
  margin: 1px;
  border-radius: 32px;
  background-color: #d26066;
  opacity: 0.5;
}

.content__intro {
  width: 100%;
  height: auto;
  text-align: center;
}

.bottom01 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 4;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.logo {
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  z-index: 1;
  display: block;
  overflow: visible;
  width: 40vw;
  height: auto;
  max-width: 1920px;
  margin: 76px auto auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.top01 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 40;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.top05 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 36;
  display: block;
  width: 100%;
  height: auto;
  max-width: 1920px;
}

.social__bg-over {
  position: absolute;
  z-index: 2;
  display: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #cb7f65;
}

.h2__background {
  background-position: 50% 0%;
  background-size: cover;
  background-attachment: scroll;
}

.quote__block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #fff;
}

.hero {
  position: relative;
  display: none;
  overflow: hidden;
  width: 100vw;
  height: 600px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  background-color: #b189c5;
  text-align: center;
}

.tag__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-box {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 64px;
  }

  .button-icon-small {
    position: static;
    top: 16px;
  }

  .button-icon-small-secondary {
    position: static;
    top: 16px;
  }

  .content__container {
    padding-top: 48px;
    padding-right: 32px;
    padding-left: 32px;
  }

  .quotes {
    margin-top: 32px;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }

  .about {
    margin-top: 64px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .footer__container {
    padding-right: 32px;
    padding-left: 32px;
  }

  .footer__paragraph {
    font-size: 2.5rem;
  }

  .hero {
    width: 1440px;
    height: 630px;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 40px;
  }

  h4 {
    font-size: 24px;
  }

  .padding-vertical-xxlarge {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .background-layer {
    background-position: 0px 0px, 50% 100%;
    background-size: 300px 300px, auto;
  }

  .paragraph-large {
    font-size: 18px;
  }

  .padding-vertical-xlarge {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .timeline_item {
    width: 100%;
    -ms-grid-columns: 64px 1fr;
    grid-template-columns: 64px 1fr;
  }

  .timeline_left {
    text-align: left;
  }

  .timeline_centre {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .timeline_date-text {
    margin-bottom: 24px;
    font-size: 36px;
  }

  .timeline_text {
    font-size: 20px;
  }

  .timeline_progress {
    left: 6px;
  }

  .margin-bottom-xlarge {
    margin-bottom: 48px;
  }

  .timeline_quote-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .timeline_quote-image {
    margin-top: 24px;
    margin-right: 0px;
  }

  .button-inverted {
    padding: 14px 24px;
  }

  .margin-bottom-small {
    margin-bottom: 24px;
  }

  .button-icon-small {
    padding: 10px 16px 10px 14px;
  }

  .button-icon-small.hide-mobile {
    display: none;
  }

  .button-icon-small-secondary {
    padding: 10px 16px 10px 14px;
  }

  .button-icon-small-secondary.margin-right-small.mobile-margin-right-zero {
    margin-right: 0px;
  }

  .footer_credits-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .text-link.text-light-grey.privacy-policy {
    margin-bottom: 16px;
  }

  .padding-vertical-footer {
    padding-top: 80px;
    padding-bottom: 64px;
  }

  .hero-nav {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .quotes {
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .about {
    margin-bottom: 32px;
  }

  .section {
    position: static;
  }

  .footer__paragraph {
    font-size: 1.8rem;
  }

  .hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 640px;
    height: 280px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 36px;
  }

  h4 {
    font-size: 22px;
  }

  .margin-bottom-medium {
    margin-bottom: 24px;
  }

  .paragraph-large {
    font-size: 16px;
  }

  .timeline_item {
    -ms-grid-columns: 48px 1fr;
    grid-template-columns: 48px 1fr;
  }

  .button-icon {
    display: none;
  }

  .button-icon-small {
    top: 18px;
    padding: 10px 14px;
    font-size: 13px;
  }

  .button-icon-small-secondary {
    top: 18px;
    padding: 10px 14px;
    font-size: 13px;
  }

  .skills {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .content__container {
    padding-top: 32px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .quotes {
    margin-top: 0px;
  }

  .about {
    margin-top: 32px;
    grid-row-gap: 32px;
    text-align: left;
  }

  .social {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .content {
    padding-bottom: 64px;
  }

  .footer__container {
    padding: 0px 16px 32px;
  }

  .footer__paragraph {
    font-size: 1.5rem;
  }

  .hero {
    width: 960px;
    height: 420px;
  }

  .tag__container {
    margin-top: 16px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) {
  #w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe1-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe4-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-d5abcf1f-3370-3eea-ccfd-66f076babfe6-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_0488106f-ce92-9b7b-01c3-8ad75f3f5e36-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_0488106f-ce92-9b7b-01c3-8ad75f3f5e39-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_5b7ae63b-6f00-e542-a023-96ea5c4a96e4-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_5b7ae63b-6f00-e542-a023-96ea5c4a96e7-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-e0f4f25e-22f1-da39-65d5-a453ca30c732-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-e0f4f25e-22f1-da39-65d5-a453ca30c735-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-ca21cc2f-00d7-84a3-f446-587e32a59c01-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-ca21cc2f-00d7-84a3-f446-587e32a59c04-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_37ab9472-ef92-a1c7-f6c5-ffbef2542159-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_37ab9472-ef92-a1c7-f6c5-ffbef254215c-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_3f36ed1c-86ad-66f2-a7e0-a2e345d5b492-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_3f36ed1c-86ad-66f2-a7e0-a2e345d5b495-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_24c49997-4a3d-4bd7-0915-1f0630d904af-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_24c49997-4a3d-4bd7-0915-1f0630d904b2-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-cea39288-a86d-bc98-0716-192fe18bb832-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-cea39288-a86d-bc98-0716-192fe18bb835-5d47b428 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
}

</style>
