<template>
  <div class="w-full font-inter h-full main-content">
    <div class="bg-black bg-opacity-25 p-4 text-secondary-500 text-center">CollectiVerse Genesis NFT public sale is live! View the collection <a href="/collections">here.</a></div>
    <!-- Hero -->
    <div class="lg:h-full py-20 px-5 w-full">
      <div class="h-full">
        <main class="flex flex-col items-center text-center space-y-6">
          <h1 class="text-5xl font-semibold text-white leading-[1]">
            Imagine building and investing in a safer DeFi and NFT space
          </h1>
          <!-- <div class="sm:hidden md:flex max-w-md mx-auto sm:flex sm:justify-center  md:mt-8">
            <Button
              btn-style="outlined"
              type="full"
              size="xlarge"
              @click="goToLP"
              ><span>EXPLORE</span></Button
            >
          </div> -->
        </main>
      </div>
    </div>
    <!-- Intro -->
    <div class="w-full py-20 flex items-center bg-primary-900">
      <div class="w-full px-5">
        <div class="m-auto flex flex-col text-center max-w-5xl space-y-6">
          <span class="text-white font-extrabold text-2xl md:text-3xl">
            CollectiVerse makes it easier to build and operate a DeFi or NFT project while protecting investors.
          </span>
          <p class="text-black text-2xl font-medium">
            We are a decentralized platform for projects to build within a system that ensures <br />
            <span class="text-bold"> investor safeguards, transparency, voting, and high yields.</span>
          </p>
        </div>
      </div>
    </div>
    <!-- Vision Cascade -->
    <div class="w-full bg-black" id="vision">
      <!-- Section ONE -->
      <div id="one">
        <div class="md:px-10 py-20 flex items-center w-full" data-aos="slide-up">
          <div
            class=" flex flex-col-reverse items-start space-y-10 lg:flex-row lg:space-y-0 lg:items-center lg:space-x-10 lg:justify-end w-full max-w-7xl mx-auto">
            <div class=" w-full lg:max-w-md flex flex-col items-start px-5 py-10 lg:py-0 space-y-8 ">
              <div class="w-full flex flex-col items-start space-y-2">
                <span class="text-primary-800 font-inter font-extrabold text-3xl mb-4">Build DeFi and NFT Projects Simpler</span>
                <div class="flex flex-col items-start space-y-10">
                  <p class="text-white font-inter font-normal text-lg text-left">
                    CollectiVerse addresses the challenge that it’s too complex and expensive to build and operate a project: from coding to treasury management to adding token utility. There is too much that can go wrong that hurts returns for builders and investors.
                  </p>
                  <p class="text-white font-inter font-normal text-lg text-left">
                    We developed middleware technology (called Layer 3) that addresses limitations in blockchain architecture, specifically Layer 1s and 2s like Ethereum, Polygon, Avalanche so it’s easier to build and operate projects, while also protecting investors. 
                  </p>
                  <p class="text-white font-inter font-normal text-lg text-left">
                      Existing DeFi or NFT projects can use one or more of our services. Plus our No-Code platform will enable an artist or off-chain business to deploy a new NFT or DeFi project without any crypto experience. It’s a Shopify for the DeFi and NFT space.

                  </p>
                  <p class="text-white font-inter font-normal text-lg text-left">
                    Middleware is critical for internet, networking, and finance industries (nearly $100B annual revenues) to address limitations in underlying protocols and operating systems. We are building it for the DeFi/NFT space, leveraging our success doing it previously. 

                  </p>
                </div>
              </div>
              <!-- <div class=" w-full sm:flex-row flex-col space-y-2 sm:space-y-0 items-start flex sm:items-center sm:space-x-2 " >
                <Button btn-style="outlined" type="full" @click="goToLPA"
                  ><span>Learn More</span></Button
                >
              </div> -->
            </div>
            <div class="max-w-4xl w-full relative overflow-hidden col-span-2 px-5">
              <div class="w-full overflow-hidden right-0">
                <img src="/images/collectiverse_layer_3.png" class="w-auto lg:w-2/3 mx-auto" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Section TWO -->
      <div id="two">
        <div class="lg:h-screen md:px-10 py-20 flex items-center bg-primary-900 w-full" data-aos="slide-up">
          <div
            class=" flex flex-col-reverse items-start space-y-10 lg:flex-row-reverse lg:space-y-0 lg:items-center lg:space-x-10 lg:justify-end w-full max-w-7xl mx-auto">
            <div class=" w-full lg:max-w-md flex flex-col items-start px-5 py-10 lg:py-0 space-y-8 ">
              <div class="w-full flex flex-col items-start space-y-2">
                <span class="text-primary-800 font-inter font-extrabold text-3xl mb-4">A Safer DeFi and NFT Space</span>
                <div class="flex flex-col items-start space-y-10">
                  <p class="text-white font-inter font-normal text-lg text-left">
                    DeFi and NFTs are transforming numerous industries. Yet there are challenges such as hacks, flash loan attacks, and project founders that blatantly steal.
                  </p>
                  <p class="text-white font-inter font-normal text-lg text-left">
                    CollectiVerse Layer 3 provides a protective platform. Our stablecoin provides a buffer to protect against hacks or bad actors with a 24 hour delay to move funds or tokens out of our ecosystem. 
                  </p>
                  <p class="text-white font-inter font-normal text-lg text-left">
                    Treasury funds are stored in a decentralized custody, which ensures investors have some say in how funds are used or transferred to prevent rugs. 
                  </p>
                </div>
              </div>
              <!-- <div class=" w-full sm:flex-row flex-col space-y-2 sm:space-y-0 items-start flex sm:items-center sm:space-x-2 " >
                  <Button btn-style="outlined" type="full" @click="goToLPB"
                    ><span>Learn More</span></Button
                  >
                </div> -->
            </div>
            <div class="max-w-4xl w-full relative overflow-hidden col-span-2 px-5">
              <div class="w-full overflow-hidden right-0">
                <img src="/images/dual_tokens.png" class="w-auto lg:w-2/3 mx-auto" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Section THREE -->
      <div id="three">
        <div class="lg:h-screen md:px-10 py-20 flex items-center w-full" data-aos="slide-up">
          <div
            class=" flex flex-col-reverse items-start space-y-10 lg:flex-row lg:space-y-0 lg:items-center lg:space-x-10 lg:justify-end w-full max-w-7xl mx-auto">
            <div class=" w-full lg:max-w-md flex flex-col items-start px-5 py-10 lg:py-0 space-y-8 ">
              <div class="w-full flex flex-col items-start space-y-2">
                <span class="text-primary-800 font-inter font-extrabold text-3xl mb-4">Collective Metaverse</span>
                <div class="flex flex-col items-start space-y-10">
                  <p class="text-white font-inter font-normal text-lg text-left">
                    CollectiVerse stands for collective metaverse. We wrapped our ecosystem into a space metaverse. This will enhance the community of each project in our ecosystem, plus enable builders and investors to share in the upside.
                  </p>
                  <p class="text-white font-inter font-normal text-lg text-left">
                    It gives every project in our ecosystem a metaverse strategy without the need for building their own. Instead together we can all build a collective one, where we share in the revenue. As an added benefit, the metaverse provides project builders and investors diversification.
                  </p>
                  <p class="text-white font-inter font-normal text-lg text-left">
                    The first step to participating in our collective metaverse is to purchase our NFT Seed collection, which are 3D animated stars and asteroids. The NFT Seed represents a discounted purchase of our future governance token, plus gamification and utility in our space metaverse.

                  </p>

                </div>
              </div>
              <!-- <div class=" w-full sm:flex-row flex-col space-y-2 sm:space-y-0 items-start flex sm:items-center sm:space-x-2 " >
                <Button btn-style="outlined" type="full" @click="goToLPC"
                  ><span>Learn More</span></Button
                >
              </div> -->
            </div>
            <div class="max-w-4xl w-full relative overflow-hidden col-span-2 px-5">
              <div class="w-full overflow-hidden right-0">
                <img src="/images/collectiverse-hands-raising.jpg" class="w-auto lg:w-full mx-auto" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- Roadmap -->
    <div id="four">
      <div class="separator m-auto" id="roadmap">
        <img src="/images/collectiverse_planet.jpg" class="w-full" />
      </div>
    </div>

    <road-map />

    <div id="team">

      <!-- Intro -->
      <div class="w-full py-20 flex items-center bg-white">
        <div class="w-full px-5">
          <div class="m-auto flex flex-col text-center max-w-6xl space-y-8">
            <span class="text-primary-900 font-extrabold text-2xl md:text-3xl">
              Team
            </span>
            <p class="text-gray-500 text-2xl font-medium">
              Our founding team is doxxed, and with our developers, KYC’d by Obsidian Council. The team has significant
              experience across numerous fields: finance, crypto, NFTs, startups, development, design, and marketing.
              Our proven ability to execute plus personal relationships with leaders in the industry make us
              well-positioned to deliver on our vision.
            </p>
          </div>
        </div>
      </div>


      <div class="mx-auto px-4 w-full bg-white py-8">
        <div class="max-w-7xl m-auto">
          <div class="grid lg:grid-cols-3 grid-cols-1 md:grid-cols-2 gap-8">
            <team-card v-for="(team, index, key) in teams" :key="key" :info="team" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import AOS from "aos";
  import {
    onMounted
  } from "@vue/runtime-core";
  // import Button from "../../components/Layouts/Button.vue";
  import RoadMap from "../../components/Layouts/RoadMap.vue";
  import TeamCard from "../../components/cards/TeamCard.vue";
  const teams = [{
      name: "Kul Singh",
      title: "Founder: Vision and Tokenomics",
      discription: "15-year startup veteran with 2 exits as founder, which include 2 middleware companies with one sold to publicly-traded Box. Over $100M in capital raised from investors including Goldman Sachs. Grew revenues from zero to tens of millions in startups founded. Formerly traded and structured interest rate and mortgage derivatives at Credit Suisse First and Merrill Lynch.",
      image: "/images/kull.png",
      linkedin: "https://www.linkedin.com/in/kulsingh/",
      twitter: "",
    },
    {
      name: "Hami Gendron",
      title: "Founder: Vision, Architect, and Developer",
      discription: "An innovator in the NFT and crypto space since 2017, Hami Gendron launched one of the first NFT marketplaces and co-founded the first NFT charity project on the blockchain: CryptOtitties (January 2018). Gendron has been involved in Augmented reality blockchain projects and space projects including Cosmic Paws’ Muttnick NFTs Project.",
      image: "/images/hami.png",
      linkedin: "https://www.linkedin.com/in/hamigendron/",
      twitter: "",
    },
    {
      name: "Natalie Labuda",
      title: "Founder: Marketing",
      discription: "10 years marketing experience including 6 years of experience in financial services. Previously a senior member of E*TRADE Morgan Stanley’s marketing team. She brings significant experience building brands, marketing strategies, social media, and community management. She is also an executive member of the women's entrepreneur group Dreamers & Doers.",
      image: "/images/Natalie.png",
      linkedin: "https://www.linkedin.com/in/natalie-labuda-34111224/",
      twitter: "",
    },

    {
      name: "Rogers Sampaio",
      title: "Founder: Full Stack Web3 Developer",
      discription: "Lead developer and co-founder of one of the first NFT marketplaces in 2018 and one of the first NFT projects (CryptOtitties). He also worked as a blockchain developer at Augmate.io and on the Muttnick Project, a space project that sent an NFT to space and was sold for over $100K for charity.",
      image: "/images/rogers.png",
      linkedin: "https://www.linkedin.com/in/rogerssampaio/",
      twitter: "",
    },
    {
      name: "Mike Mongo",
      title: "Advisor",
      discription: "Brings long-standing relationships with CEO’s and leadership across many industries. He brings invaluable mentorship and coaching. He also has top-tier relationships in the space industry that includes NASA Astronauts and CEOs, which will be invaluable as we build our planetary metaverse and seek to have a voice in space itself. He’s the author of the best-selling children’s book The Astronaut Instruction Manual, and host of YouTube channel Mike Mongo’s Astronaut Adventures. He is co-creator of Cosmic Paws and the Muttniks NFTs and a co-founder of OBEY",
      image: "/images/mike.png",
      linkedin: "https://www.linkedin.com/in/mikemongo",
      twitter: "https://twitter.com/MikeMongo",
    },
  ];
  export default {
    components: {
      // Button,
      RoadMap,
      TeamCard
    },
    setup() {
      onMounted(() => {
        AOS.init();
      });
      // const goToLP = () => {

      //   window.location = "#one";
      // };
      // const goToLPA = () => {

      //   window.location = "#two";
      // };
      // const goToLPB = () => {

      //   window.location = "#three";
      // };
      // const goToLPC = () => {

      //   window.location = "#four";
      // };
      return {
        // goToLP,
        // goToLPA,
        // goToLPB,
        // goToLPC,
        teams,
      };
    },
  };
</script>
<style>
  .main-content {
    scroll-behavior: smooth;
  }
</style>
