<template>
<div class="gog-app bg-black bg-opacity-25 lg:bg-cover bg-top bg-no-repeat sm-bg-size" style="background-image:url(/images/bg.jpg);    background-blend-mode: multiply!important; background-attachment: fixed">
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
  </metainfo>
  <Header @on:login="showLoginPopUp" @on:logout="logout" />
  <div class="body bg-transparent">
    <router-view @on:login="showLoginPopUp" :registered="registrationDone" />
    <Modal />
    <toast />
    <div class="bg-primary-900 h-8"></div>
  </div>
  <Footer />
  <loading />
  <ConnectWallet
      :login_modal="showLogin"
      @on:close="closeLoginModal"
      @on:connect="setEvents($event)"
    />
</div> 
</template>

<script>
import Header from "@/components/Layouts/Header.vue";
import Footer from "@/components/Layouts/Footer.vue";
import Toast from "./components/Shared/Toast.vue";
import { onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Modal from './components/Modals/Modal.vue';
import Loading from './components/Shared/Loading.vue';
import ConnectWallet from './components/Modals/ConnectWallet.vue';


export default {
  computed:{
    getRouteName(){
      return this.$route.name;
    }
  },
  components: {
    Header,
    Footer,
    Toast,
    Modal,
    Loading,
    ConnectWallet,
  },
  setup() {

    const chains = {
      "0xa869": "fuji",
      "0xa86a": "avalanche",
      "0x1": "mainnet",
      "0x13881": "mumbai",
      "0x89": "polygon",
      "0x38": "bsc",
      "0x61": "bsctestnet",
      "0xaa36a7": "sepolia"
    };

    const showLogin = ref(false);
    const showChains = ref(false);
    const registrationDone = ref(false);

    const store = useStore();
    const router = useRouter();

    const showChainSelector = () => {
      showChains.value = true;
    };

    const closeChainSelector = () => {
      showChains.value = false;
    }

    const showLoginPopUp = () => {
      console.log("here");
      showChains.value = false;
      showLogin.value = true;
      
    };

    const closeLoginModal = () => {
      showLogin.value = false;
      
    };

    const logout = () => {
       registrationDone.value = false;
    }

    const setEvents = async (event) => {


      registrationDone.value = event.registered;

      const currentChainId = await window.ethereum.request({
        method: 'eth_chainId',
      });

      console.log(currentChainId);

      checkChain(chains[currentChainId])
      

      window.ethereum.on("chainChanged", (chainId) => {
        checkChain(chains[chainId]);
      });

      window.ethereum.on("accountsChanged", () => {
        store.dispatch("user/logoutUser");
        router.push("/");
      });
    };

    const checkChain = (chain) => {
      const currentChain = store.getters['blockchain/getCurrentChain'];

      console.log(chain);

      if (chain !== currentChain.name) {
        store.dispatch("NotificationStore/SET_MODAL_TITLE", {
          title: "You are on the wrong network.",
        });
        store.dispatch("NotificationStore/SET_MODAL_MESSAGE", {
          message: `This site is intended to work in ${currentChain.name} chain. Please change your network and try again.`,
        });
        store.dispatch("NotificationStore/SET_MODAL_BUTTONS", {
          buttons: [
            { name: "OK", btnStyle: "primary", action: function () {
              store.dispatch("NotificationStore/SET_OPEN_MODAL");
              store.dispatch("user/logoutUser");
              router.push("/");
            } },
            
          ],
        });
        store.dispatch("NotificationStore/SET_OPEN_MODAL");
      }
    };
 
    onMounted(async () => {

      await store.dispatch('blockchain/initWallets');
      await store.dispatch('blockchain/getBlockChain');
    
      const provider = store.getters['blockchain/getCurrentProvider'];
      if (provider) {
        setEvents({provider: provider, registered: false});
      }

      
    });

    return {
      showLoginPopUp,
      closeLoginModal,
      showLogin,
      showChains,
      setEvents,
      registrationDone,
      logout,
      showChainSelector,
      closeChainSelector
    };
    
  }
};
</script>

<style >
.body {
  min-height: calc(100vh - 400px);
  position: relative;
  scroll-behavior: smooth;
}
#app {
  scroll-behavior: smooth;
}
.gog-app {
  position: relative;
  scroll-behavior: smooth;
}
@media screen and (max-width:1023px) {
.sm-bg-size{
  background-size: 1000px;
}
}

@media screen and (max-width:767px) {
.sm-bg-size{
  background-size: 1200px;
}
}

</style>
