<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_296_1113)">
      <path
        d="M1.28261 9.21295L4.96933 10.5958L6.39632 15.2078C6.48763 15.5032 6.84704 15.6124 7.0856 15.4164L9.14064 13.7327C9.35606 13.5563 9.66288 13.5476 9.888 13.7118L13.5946 16.4162C13.8498 16.6026 14.2113 16.4621 14.2753 16.1522L16.9906 3.02631C17.0604 2.68778 16.7295 2.40537 16.4087 2.53004L1.27829 8.39595C0.9049 8.54066 0.908154 9.07193 1.28261 9.21295ZM6.16635 9.85968L13.3716 5.39988C13.5011 5.31997 13.6343 5.49593 13.5231 5.59959L7.5767 11.1546C7.36768 11.3501 7.23285 11.6118 7.19467 11.8958L6.99211 13.4044C6.96528 13.6058 6.68373 13.6258 6.62843 13.4309L5.84939 10.6799C5.76016 10.3661 5.89019 10.031 6.16635 9.85968Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_296_1113"
        x="0"
        y="0.5"
        width="20"
        height="18"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.53 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_296_1113"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_296_1113"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>