import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import VueEasyLightbox from "vue-easy-lightbox";
import VueClipboard from 'vue3-clipboard';
// import { auth } from "../src/firebase/firebase";
import CarouselCard from 'vue-carousel-card'
import 'vue-carousel-card/styles/index.css'
import VueLazyload from '@jambonn/vue-lazyload'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";



import VueSmoothScroll from 'vue3-smooth-scroll'

import { DatePicker, TimePicker } from 'ant-design-vue';
//import 'ant-design-vue/dist/antd.css';

import "firebaseui/dist/firebaseui.css";

import store from "./store";

import 'aos/dist/aos.css'

const loadimage = require('./assets/images/loading_image.png')
const errorimage = require('./assets/images/loading_image.png')

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://8dd101b3faeb4b1b9e97c712030616f5@o4503959773380608.ingest.sentry.io/4503959775019008",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "collectiverse-dev.web.app", "collectiverse.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


app.use(router).use(VueEasyLightbox).use(store).use(DatePicker).use(TimePicker).use(VueClipboard).use(CarouselCard).use(VueSmoothScroll).use(Vue3Lottie)
.use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1
  }).mount("#app");

// auth.onAuthStateChanged((user) => {
//   if (user) {
//     store.dispatch("authStore/getAdminAuth", user.uid);
//   } else {
//     store.dispatch("authStore/clearAuth");
//   }
// });
