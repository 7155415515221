<template>
    <div>
    <footer class="bg-black" aria-labelledby="footerHeading">
      <h2 id="footerHeading" class="sr-only">Footer</h2>
      <div class="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:pt-16 lg:px-8">
        
        <div class="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
          <div class="flex space-x-6 md:order-3">
            <a 
              v-for="item in footerNavigation.social" 
              :key="item.name" 
              :href="item.href" 
              target="_blank" 
              class="text-white hover:text-gray-500"
              rel="noopener noreferrer"
            >
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
           
          <p class="mt-8 md:mt-0 md:order-1">
            <a href="/" class="text-base text-white">&copy; Collectiverse. All rights reserved.</a>
          </p>
        </div>
      </div>
    </footer>
  </div>
  <LoginModal :login_modal="login_modal" @on:close="login_modal = false" />
</template>


<script>
import { computed } from 'vue'
import { ref } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon,ArrowDownIcon,CalendarIcon  } from '@heroicons/vue/outline'
import {  ArrowSmUpIcon, MenuAlt1Icon, ClockIcon   } from '@heroicons/vue/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ViewGridAddIcon , XIcon } from '@heroicons/vue/outline'

import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useStore } from 'vuex';
import LoginModal from '@/components/Modals/Login_Modal.vue'
import IconTwitter1 from '../Icons/IconTwitter1.vue';
import IconTelegram from '../Icons/IconTelegram.vue';
import IconDiscord from '../Icons/IconDiscord.vue';
import IconMedium from '../Icons/IconMedium.vue';
import IconGithub from '../Icons/IconGithub.vue';

const footerNavigation = {
  account: [
    { name: 'Login / Signup', href: '/my-account/sfd'},
    { name: 'Admin', href: '/my-account/sfd' },
    { name: 'Client', href: '/my-account/sfd' },
    { name: 'Buyer', href: '/my-account/sfd' },
 
  ],
  support: [
    { name: 'Contact Us', href: '/contact' }
  ],
   
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/collectiverse_',
      icon: <IconTwitter1 />
    },

    {
      name: 'Discord',
      href: 'https://discord.gg/collectiverse',
      icon: <IconDiscord />
    },
    
  ],
}


export default {
  methods:{
    ChangeUserRole(role){
      this.$store.commit('user/changeType', role)
    }
  },
  components: {
 
      Disclosure,
      DisclosureButton,
      DisclosurePanel,
      ChevronDownIcon,
      ArrowSmUpIcon,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      ArrowDownIcon,
      MenuAlt1Icon ,
      Dialog,
      DialogOverlay,
      DialogTitle,
      TransitionChild,
      TransitionRoot,
      ViewGridAddIcon ,
      ClockIcon,
      XIcon,
      CalendarIcon,
      LoginModal,
      IconTwitter1,
      IconTelegram,
      IconDiscord,
      IconMedium,
      IconGithub
  },
  setup() {

    const store = useStore();
 
    const login = ref(false);
    const login_modal = ref(false);
    const newsletter_modal = ref(false);
    const currentAddress = computed(() => store.getters['blockchain/getCurrentAddress']);
    const user = computed(() => store.getters['user/getUser']);

    const openModal = async () => {
      await store.dispatch("NotificationStore/SET_MODAL_MESSAGE", { message: 'Thank you for subscribing to GOG Newsletter' });
      await store.dispatch("NotificationStore/SET_MODAL_TITLE", { title: 'Newsletter' });
      await store.dispatch("NotificationStore/SET_MODAL_BUTTONS", { buttons: [] });
      await store.dispatch("NotificationStore/SET_OPEN_MODAL");
    }

    return {
      footerNavigation,
      login_modal,
      login,
      newsletter_modal,
      currentAddress,
      user,
      openModal
    }
  },
}
</script>