// Works for web3 1.0 and pre-1.0 versions
import axios from "axios";
//import { ethers } from "ethers";
import FERC721 from "../contracts/FERC721.json";
import Object from "../contracts/Object.json";

import USDCAbi from "../contracts/USDCAbi.json";
import BUSD from "../contracts/BUSD.json";

//import Vault from "../contracts/Vault.json";
import VaultFactory from "../contracts/VaultFactory.json";
import BasketFactory from "../contracts/JERC72BasketFactory.json";

let currNetwork = process.env.VUE_APP_NETWORK;

let chainsIds = {
  43114: 'avalanche',
  137: 'polygon',
  56: 'bsc',
  1: 'mainnet',
}

if (currNetwork == 'testnets') {
  chainsIds = {
    43113: 'fuji',
    80001: 'mumbai',
    97: 'bsctestnet',
    11155111: 'sepolia'
  }
}

const addresses = {
  ropsten: {
    auction: "0xC3dC1B68B3D077Fe23178206462f792943f75b3A",
    ERC721: "0xBcA770B20C37331887d7074481bc3B61B75e2641",
    USDC: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
  },
  // rinkeby: {
  //   auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
  //   ERC721: "0x2Ed3125f1832BeEDEE38adE6541F93217bbdC29f",
  //   USDC: "0xeb8f08a975ab53e34d8a0330e0d34de942c95926",
  // },
  fuji: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    USDC: process.env.VUE_APP_FUJI_USDC,
    object: process.env.VUE_APP_FUJI_SALES,
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1",
    settings: "0x1C0857f8642D704ecB213A752A3f68E51913A779",
    basketFactory: "0xee727b734aC43fc391b67caFd18e5DD4Dc939668"
  },
  avalanche: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    USDC: process.env.VUE_APP_AVALANCHE_USDC,
    object: process.env.VUE_APP_AVALANCHE_SALES,
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1",
    settings: "0x1C0857f8642D704ecB213A752A3f68E51913A779",
    basketFactory: "0xee727b734aC43fc391b67caFd18e5DD4Dc939668"
  },
  bsctestnet: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    USDC: process.env.VUE_APP_BSCTESTNET_BUSD,
    object: process.env.VUE_APP_BSCTESTNET_SALES,
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1",
    settings: "0x1C0857f8642D704ecB213A752A3f68E51913A779",
    basketFactory: "0xee727b734aC43fc391b67caFd18e5DD4Dc939668"
  },
  bsc: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    USDC: process.env.VUE_APP_BSC_BUSD,
    object: process.env.VUE_APP_BSC_SALES,
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1",
    settings: "0x1C0857f8642D704ecB213A752A3f68E51913A779",
    basketFactory: "0xee727b734aC43fc391b67caFd18e5DD4Dc939668"
  },
  mumbai: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    USDC: process.env.VUE_APP_MUMBAI_USDC,
    object: process.env.VUE_APP_MUMBAI_SALES,
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1",
    settings: "0x1C0857f8642D704ecB213A752A3f68E51913A779",
    basketFactory: "0xee727b734aC43fc391b67caFd18e5DD4Dc939668"
  },
  polygon: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    USDC: process.env.VUE_APP_POLYGON_USDC,
    object: process.env.VUE_APP_POLYGON_SALES,
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1",
    settings: "0x1C0857f8642D704ecB213A752A3f68E51913A779",
    basketFactory: "0xee727b734aC43fc391b67caFd18e5DD4Dc939668"
  },
  sepolia: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    USDC: process.env.VUE_APP_SEPOLIA_USDC,
    object: process.env.VUE_APP_SEPOLIA_SALES,
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1",
    settings: "0x1C0857f8642D704ecB213A752A3f68E51913A779",
    basketFactory: "0xee727b734aC43fc391b67caFd18e5DD4Dc939668"
  },
  mainnet: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    ERC721: process.env.VUE_APP_ERC721,
    USDC: process.env.VUE_APP_MAINNET_USDC,
    object: process.env.VUE_APP_MAINNET_SALES,
  },
};


let decimals = Math.pow(10, 6);

const testAuthentication = async () => {
  try {
    const url = `https://api.pinata.cloud/data/testAuthentication`;
    const response = await axios.get(url, {
      headers: {
        pinata_api_key: process.env.VUE_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.VUE_APP_PINATA_API_SECRET,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const pinFile = async (image, name) => {
  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  //we gather a local file from the API for this example, but you can gather the file from anywhere
  let data = new FormData();
  data.append("file", image);
  const metadata = {
    name: name,
  };
  data.append("metadata", metadata);
  const results = await axios.post(url, data, {
    headers: {
      "Content-Type": `multipart/form-data; boundary= ${data._boundary}`,
      pinata_api_key: process.env.VUE_APP_PINATA_API_KEY,
      pinata_secret_api_key: process.env.VUE_APP_PINATA_API_SECRET,
    },
  });
  return results;
};

const pinJson = async (json) => {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

  const results = await axios.post(url, json, {
    headers: {
      pinata_api_key: process.env.VUE_APP_PINATA_API_KEY,
      pinata_secret_api_key: process.env.VUE_APP_PINATA_API_SECRET,
    },
  });
  return results.data;
};

const mintNft = async (web3Instance, address, vouchers) => {
  const web3 = web3Instance();
  //const accounts = await web3.eth.getAccounts();
  const chainId = await web3.eth.getChainId();

  const currentNetwork = chainsIds[chainId];
  if (!currentNetwork) {
    throw new Error('Wrong Network Selected');
  }

  const theVoucher = vouchers[currentNetwork];

    // Create contract object
    const tokenContractERC1155 = new web3.eth.Contract(
      Object,
      addresses[currentNetwork].object
    );
    const receipt = await tokenContractERC1155.methods
      .mintObject(address, theVoucher)
      .send({ from: address});

    return {contractAddress: addresses[currentNetwork].object, receipt: receipt};
  
};

const mintBasket = async () => {
  const accounts = await window.web3.eth.getAccounts();
  //window.web3 = new Web3(window.ethereum);
  // Create contract object
  const basketFactoryContract = new window.web3.eth.Contract(
    BasketFactory,
    addresses[currNetwork].basketFactory
  );
  const receipt = await basketFactoryContract.methods
    .createBasket()
    .send({ from: accounts.result[0] });

  return receipt;
};

const approveBasket = async (basketaddress) => {
  const accounts = await window.web3.eth.getAccounts();
  //window.web3 = new Web3(window.ethereum);
  // Create contract object
  const basketContract = new window.web3.eth.Contract(
    FERC721,
    basketaddress
  );
  const receipt = await basketContract.methods
    .setApprovalForAll(addresses[currNetwork].vaultFactory, true)
    .send({ from: accounts.result[0] });

  return receipt;
};

const mintVault = async (name, ticker, basketaddress, id, supply, listPrice, fee) => {
  const accounts = await window.web3.eth.getAccounts();
  //window.web3 = new Web3(window.ethereum);
  // Create contract object
  const vaultFactoryContract = new window.web3.eth.Contract(
    VaultFactory,
    addresses[currNetwork].vaultFactory
  );
  const receipt = await vaultFactoryContract.methods
    .mint(name, ticker, basketaddress, 0, supply, listPrice, fee)
    .sendAsync({ from: accounts.result[0] });

  return receipt;
};

const approveNFT = async (nftAddress, id, basketaddress) => {
  const accounts = await window.web3.eth.getAccounts();
  //window.web3 = new Web3(window.ethereum);
  // Create contract object
  const nftContract = new window.web3.eth.Contract(
    FERC721,
    nftAddress
  );
  const receipt = await nftContract.methods
    .approve(basketaddress, id)
    .send({ from: accounts.result[0] });

  return receipt;
};

const transferNFT = async (nftAddress, id, basketaddress) => {
  console.log('Here');

  const accounts = await window.web3.eth.getAccounts();
  //window.web3 = new Web3(window.ethereum);
  // Create contract object
  const nftContract = new window.web3.eth.Contract(
    FERC721,
    nftAddress
  );
  
  try {
   
    const receipt = await nftContract.methods
      .safeTransferFrom(accounts.result[0], basketaddress, id)
      .send({ from: accounts.result[0] });

    return receipt;
  } catch(error) {
    console.log(error);
  }
};
 
const tokenAllowance = async (amount, address, web3Instance) => {
  // const accounts = await window.web3.eth.getAccounts();
  const web3 = web3Instance();
  const chainId = await web3.eth.getChainId();
  const currentNetwork = chainsIds[chainId];
  console.log(currentNetwork);
  if (!currentNetwork) {
    throw new Error('Wrong Network Selected');
  }

  let abi = USDCAbi;
  let total = amount * decimals;
  if (currentNetwork == 'bsctestnet' || currentNetwork == 'bsc') {
    decimals = Math.pow(10, 18);
    total = web3.utils.toBN(amount.toString() + '000000000000000000');
    abi = BUSD;

  } else {
    decimals = Math.pow(10, 6);
  }

  // Create contract object
  const USDCContract = new web3.eth.Contract(
    abi,
    addresses[currentNetwork].USDC
  );

  const result = await USDCContract.methods.balanceOf(address).call();
  const balance = result / decimals;

  

  if (balance < parseInt(total)/decimals) {
    throw new Error('Insufficient balance, please fund your account first');
  }

  const receipt = await USDCContract.methods
    .approve(addresses[currentNetwork].object, total)
    .send({ from: address });

  return receipt;
};
const checkApproved = async (web3Instance, address) => {
  const web3 = web3Instance();
  const chainId = await web3.eth.getChainId();
  const currentNetwork = chainsIds[chainId];

  if (!currentNetwork) {
    throw new Error('Wrong Network Selected');
  }

  let abi = USDCAbi;
  if (currentNetwork == 'bsctestnet' || currentNetwork == 'bsc') {
    abi = BUSD;
  }
  const USDCContract = new web3.eth.Contract(
    abi,
    addresses[currentNetwork].USDC
  );
  const receipt = await USDCContract.methods
    .allowance(address, addresses[currentNetwork].object)
    .call();
  const balance = receipt / decimals;
  return balance;
}
const checkBalance = async (web3Instance, address) => {
  const web3 = web3Instance();
  const chainId = await web3.eth.getChainId();
  const currentNetwork = chainsIds[chainId];

  if (!currentNetwork) {
    throw new Error('Wrong Network Selected');
  }

  //Create contract object
  const USDCContract = new web3.eth.Contract(
    USDCAbi,
    addresses[currentNetwork].USDC
  );

  const result = await USDCContract.methods.balanceOf(address).call();

  const balance = result / decimals;
  console.log(balance);

  return balance;
};

const getWhiteList = async (web3Instance, address) => {
  const web3 = web3Instance();
  const chainId = await web3.eth.getChainId();
  const currentNetwork = chainsIds[chainId];

  if (!currentNetwork) {
    throw new Error('Wrong Network Selected');
  }
  // const accounts = await window.web3.eth.getAccounts();
  //window.web3 = new Web3(window.ethereum);
  console.log(address)
  //const addresses = await web3.eth.getAccounts();
  //Create contract object
  const nftContract = new web3.eth.Contract(
    FERC721,
    addresses[currentNetwork].ERC721
  );

  const result = await nftContract.methods.getWhiteListForAddress(address).call();

  return {price: web3.utils.fromWei(result[0], "ether"), amount: result[1]};
};

const getTxReceipt = async (tx) => {
  try {
    const receipt = await window.web3.eth.getTransactionReceipt(tx);
    return receipt;
  } catch (error) {
    console.log(error);
  }
};

const getDecimal = (value) => {
  return window.web3.utils.hexToNumber(value);
};

export {
  testAuthentication,
  pinFile,
  pinJson,
  mintNft,
  getTxReceipt,
  tokenAllowance,
  getDecimal,
  checkBalance,
  checkApproved,
  mintBasket,
  approveBasket,
  approveNFT,
  transferNFT,
  mintVault,
  getWhiteList
};
