<template>
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_296_1116)">
      <path
        d="M17.5843 3.34359C16.3733 2.7773 15.0747 2.36008 13.7169 2.12112C13.6922 2.11651 13.6675 2.12803 13.6548 2.15108C13.4877 2.45381 13.3027 2.84875 13.1732 3.15917C11.7128 2.93635 10.2599 2.93635 8.8295 3.15917C8.69993 2.84185 8.50822 2.45381 8.34046 2.15108C8.32772 2.1288 8.30302 2.11728 8.27829 2.12112C6.92126 2.35931 5.62265 2.77653 4.41091 3.34359C4.40042 3.3482 4.39143 3.35589 4.38546 3.36587C1.92227 7.11625 1.2475 10.7744 1.57852 14.3873C1.58002 14.405 1.58975 14.4219 1.60323 14.4326C3.22837 15.6489 4.8026 16.3873 6.3476 16.8768C6.37232 16.8845 6.39852 16.8752 6.41426 16.8545C6.77973 16.3458 7.10551 15.8095 7.38484 15.2455C7.40132 15.2125 7.38558 15.1733 7.35189 15.1602C6.83515 14.9605 6.3431 14.7169 5.86979 14.4403C5.83235 14.418 5.82935 14.3634 5.86379 14.3373C5.96339 14.2613 6.06302 14.1821 6.15813 14.1022C6.17534 14.0876 6.19932 14.0845 6.21955 14.0937C9.32902 15.5406 12.6954 15.5406 15.7682 14.0937C15.7884 14.0838 15.8124 14.0868 15.8303 14.1014C15.9255 14.1813 16.0251 14.2613 16.1254 14.3373C16.1599 14.3634 16.1576 14.418 16.1202 14.4403C15.6469 14.7223 15.1548 14.9605 14.6373 15.1595C14.6036 15.1725 14.5886 15.2125 14.6051 15.2455C14.8905 15.8087 15.2162 16.3451 15.575 16.8537C15.5899 16.8752 15.6169 16.8845 15.6416 16.8768C17.1941 16.3873 18.7683 15.6489 20.3935 14.4326C20.4077 14.4219 20.4167 14.4057 20.4182 14.388C20.8144 10.2112 19.7546 6.58302 17.609 3.36664C17.6038 3.35589 17.5948 3.3482 17.5843 3.34359ZM7.84918 12.1874C6.91302 12.1874 6.14165 11.3115 6.14165 10.2358C6.14165 9.1601 6.89806 8.28418 7.84918 8.28418C8.80777 8.28418 9.57168 9.16779 9.5567 10.2358C9.5567 11.3115 8.80028 12.1874 7.84918 12.1874ZM14.1625 12.1874C13.2264 12.1874 12.455 11.3115 12.455 10.2358C12.455 9.1601 13.2114 8.28418 14.1625 8.28418C15.1211 8.28418 15.885 9.16779 15.87 10.2358C15.87 11.3115 15.1211 12.1874 14.1625 12.1874Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_296_1116"
        x="0.5"
        y="0.120117"
        width="23"
        height="18.7598"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.53 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_296_1116"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_296_1116"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>