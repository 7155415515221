<template>
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_296_1119)">
      <path
        d="M17.5637 4.14537L19 2.79547V2.5H14.0245L10.4785 11.1712L6.44425 2.5H1.22725V2.79547L2.905 4.77979C3.0685 4.92642 3.154 5.14084 3.13225 5.35674V13.1548C3.184 13.4355 3.091 13.7243 2.89 13.9284L1 16.1788V16.4706H6.35875V16.1751L4.46875 13.9284C4.264 13.7236 4.16725 13.4399 4.2085 13.1548V6.40969L8.9125 16.4742H9.45925L13.504 6.40969V14.4273C13.504 14.6388 13.504 14.6822 13.363 14.8208L11.908 16.2038V16.5H18.967V16.2046L17.5645 14.8554C17.4415 14.764 17.3778 14.6108 17.404 14.4619V4.53884C17.3778 4.38927 17.4408 4.236 17.5637 4.14537Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_296_1119"
        x="0"
        y="0.5"
        width="22"
        height="18"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.53 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_296_1119"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_296_1119"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>