<template>
  <svg
    width="23"
    height="25"
    viewBox="0 0 23 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_296_1122)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 2.5C5.477 2.5 1 6.984 1 12.517C1 16.942 3.865 20.697 7.839 22.021C8.339 22.113 8.521 21.804 8.521 21.538C8.521 21.301 8.513 20.67 8.508 19.835C5.726 20.44 5.139 18.492 5.139 18.492C4.685 17.334 4.029 17.026 4.029 17.026C3.121 16.406 4.098 16.418 4.098 16.418C5.101 16.488 5.629 17.45 5.629 17.45C6.521 18.98 7.97 18.538 8.539 18.282C8.631 17.635 8.889 17.194 9.175 16.944C6.955 16.691 4.62 15.831 4.62 11.993C4.62 10.9 5.01 10.005 5.649 9.305C5.546 9.052 5.203 8.033 5.747 6.655C5.747 6.655 6.587 6.385 8.497 7.681C9.31277 7.45851 10.1544 7.34519 11 7.344C11.85 7.348 12.705 7.459 13.504 7.681C15.413 6.385 16.251 6.654 16.251 6.654C16.797 8.033 16.453 9.052 16.351 9.305C16.991 10.005 17.379 10.9 17.379 11.993C17.379 15.841 15.04 16.688 12.813 16.936C13.172 17.245 13.491 17.856 13.491 18.791C13.491 20.129 13.479 21.21 13.479 21.538C13.479 21.806 13.659 22.118 14.167 22.02C16.1583 21.3521 17.8893 20.0753 19.1155 18.37C20.3416 16.6648 21.0009 14.6173 21 12.517C21 6.984 16.522 2.5 11 2.5Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_296_1122"
        x="0"
        y="0.5"
        width="24"
        height="23.5381"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.53 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_296_1122"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_296_1122"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>