<template>
  <div
  :class="{' w-full':animate_menu,' w-0':!animate_menu}"
    class="
      ease-in-out duration-300
      grid grid-cols-5
      max-w-sm overflow-hidden
      absolute
      z-50
      right-0
      top-0
      shadow
      bg-white
      h-auto
      rounded-bl-xl
    "
    style="max-height: 636px"
  >
    <div
      class="
        col-span-4
        flex-1 flex flex-col
        min-h-0
        bg-gray-700
        font-inter font-medium
      "
    >
      <div class="flex-1 flex flex-col pt-10 pb-10 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <logo />
        </div>
        <nav class="mt-10 flex-1 px-2 space-y-1" aria-label="Sidebar">
          <div
          v-for="item in navigation"
          :key="item.name"
          >
            <router-link
              :to="{name: item.href}"
              @click="closeMenu"
              v-if="!item.fullUrl"
              
              :class="[
              currentRouteName === item.href 
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-white hover:bg-white hover:text-black',
                'group flex items-center px-2 py-2 text-lg font-medium rounded-md',
              ]"
            >
              <component
                :is="item.icon"
                class="mr-3 flex-shrink-0 h-6 w-6 text-primary-500"
                aria-hidden="true"
              />
              <span class="flex-1">
                {{ item.name }}
              </span>
            </router-link>
            <a target="_blank" :href="item.href" v-else :class="[
              currentRouteName === item.href 
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-white hover:bg-white hover:text-black ',
                'group flex items-center px-2 py-2 text-lg font-medium rounded-md lg:hidden',
              ]"><component
                :is="item.icon"
                class="mr-3 flex-shrink-0 h-6 w-6 text-primary-500"
                aria-hidden="true"
              />
              <span class="flex-1">
                {{ item.name }}
              </span></a>
          </div>
        </nav>
      </div>
      <div class="flex-shrink-0 flex bg-gray-800 p-4" v-if="blockchainAddress">
        <router-link :to="{name:'MyAccount',params:{address:blockchainAddress}}"  class="flex-shrink-0 w-full group block">
          <div class="flex items-center">
            <div>
              <UserImage :src="avatar" :custom_height="8" />
              
            </div>
            <div class="ml-3">
              <p class="text-lg font-medium text-gray-700">{{ user.username }}</p>
              <div class="flex items-center space-x-1">
                <p class="text-lg font-medium text-primary-500 hover:text-white">
                  {{blockchainAddress ? blockchainAddress.replace(blockchainAddress.substring(8,blockchainAddress.length - 4), "....") : 'N/A'}}
                </p>
                
            
               
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div class="flex flex-col items-start space-y-6 p-4 pt-6 pb-6 bg-gray-900" v-if="blockchainAddress">
        <div class="group flex items-center"><box-icon name="diamond" color="white" class="mr-3" />
          <router-link :to="{name:'My_collections'}" class=" font-medium text-lg font-inter cursor-pointer text-primary-500 hover:text-white"
            >My Assets</router-link
          >
        </div>
        <div class="group flex items-center"><box-icon name="wallet" color="white" class="mr-3" />  
          <a href="#" @click.prevent="logout" class="font-medium text-lg font-inter cursor-pointer text-primary-500 hover:text-white"
            >Disconnect</a
          >
        </div>  
      </div>
       <div class="flex  items-center space-x-2 p-4  bg-gray-800" v-else >
        
           <box-icon name="wallet" color="white" />
       
        <a href="javascript:void(0)" @click.prevent="login" class="cursor-pointer text-primary-500 hover:text-white font-medium text-lg font-inter"
          >Connect Wallet</a
        >
      </div>
    </div>
    <div class="cursor-pointer bg-white hover:bg-primary-500 text-primary-500 hover:text-white" @click="closeMenu" >
      
      <div class="flex p-4">
        <div class="m-auto mt-5"><XIcon class="w-8 h-8" /></div>
      </div>
    </div>
  </div>
</template>

<script>


import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

import {
  DocumentDuplicateIcon,
  XIcon,
  ChartSquareBarIcon,
  UserIcon,
  BookOpenIcon,
  MailIcon,

} from "@heroicons/vue/outline";
  import Logo from './Logo.vue';
import { computed, ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { storage } from "../../firebase/firebase";
import UserImage from './UserImage.vue';
import 'boxicons';

const navigation = [
  { name: "Whitepaper", icon: ChartSquareBarIcon, href: "https://wp.collectiverse.com/collectiverse/our-vision/overview-and-vision", fullUrl: true},
  { name: "Collections", icon: ChartSquareBarIcon, href: "Explore", fullUrl: false },
  { name: "Learn More", icon: BookOpenIcon, href: "LearnMore", fullUrl: false },
  { name: "Contact Us", icon: MailIcon, href: "Contact", fullUrl: false },
  
];

export default {
  props: {
    animate_menu: {
      type: Boolean,
      default: false,
    },
    
   
  },
  computed:{
    currentRouteName() {
        return this.$route.name;
    }
  },
  components: {
    DocumentDuplicateIcon,
    XIcon,
    UserIcon,
    ChartSquareBarIcon,
    Logo,
    BookOpenIcon,
    UserImage,
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,

  },

  emits: ['on:login'],    
  setup(props, {emit}) {

    const store = useStore();

    const blockchainAddress = computed(() => store.getters['user/getBlockchainAddress']);
    const user = computed(() => store.getters['user/getUser']);
    const avatar = ref();

    const login = () => {
      
      emit('on:login');

    }

    const logout = () => {
      emit('on:logout');
    }

    const getAvatar = async () => 
    {
        const image = store.getters['user/getAvatar'];

        if (image) {
            var storageRef = storage.ref();
            const url = await storageRef.child(image).getDownloadURL();
            avatar.value = url;
        }
    };

    return {
      navigation,
      login,
      logout,
      blockchainAddress,
      getAvatar,
      avatar,
      user
    };
  },
  methods: {
    closeMenu() {
      this.$emit("closeMenu");
    },
  },
};
</script>

<style scoped>
  .userIcon {
    color:#ffffff;
  }
</style>

