<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    class="w-6 h-6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_347_160)">
      <path
        d="M4.95532 10.8667L5.69906 12.152H8.73403L9.17222 11.3946L6.91107 7.4873L4.95532 10.8667Z"
        fill="#2EC8CF"
      />
      <path
        d="M11.6719 14.7743L12.9173 12.6221H10.4263L11.6719 14.7743Z"
        fill="#2EC8CF"
      />
      <path
        d="M9.44425 3.11035L7.18311 7.01765L9.44425 10.925L11.7056 7.01765L9.44425 3.11035Z"
        fill="#2EC8CF"
      />
      <path
        d="M7.21663 14.7743L8.46222 12.6221H5.97119L7.21663 14.7743Z"
        fill="#2EC8CF"
      />
      <path
        d="M4.21143 12.1524H5.15527L4.68335 11.3369L4.21143 12.1524Z"
        fill="#2EC8CF"
      />
      <path
        d="M6.63918 7.01738L5.25775 4.63037L3.302 8.00959L4.68343 10.3967L6.63918 7.01738Z"
        fill="#2EC8CF"
      />
      <path
        d="M9.44426 0.137695C4.47374 0.137695 0.444336 4.16717 0.444336 9.13777C0.444336 14.1084 4.47374 18.1378 9.44426 18.1378C14.4148 18.1378 18.4442 14.1084 18.4442 9.13777C18.4442 4.16717 14.4148 0.137695 9.44426 0.137695ZM15.4929 12.6219H13.4614L11.6718 15.7139L9.8826 12.6219H9.00607L7.21654 15.7139L5.42716 12.6219H3.39574L4.41145 10.8668L2.75805 8.00975L5.25775 3.69077L6.91114 6.54781L9.44441 2.17062L11.9777 6.54781L13.6311 3.69077L16.1308 8.0099L14.4772 10.8668L15.4929 12.6219Z"
        fill="#2EC8CF"
      />
      <path
        d="M11.9775 7.4873L9.71606 11.3946L10.1544 12.152H13.1894L13.9333 10.8667L11.9775 7.4873Z"
        fill="#2EC8CF"
      />
      <path
        d="M13.6309 4.63037L12.2495 7.01738L14.2053 10.3967L15.5867 8.00959L13.6309 4.63037Z"
        fill="#2EC8CF"
      />
      <path
        d="M13.7334 12.1524H14.6772L14.2053 11.3369L13.7334 12.1524Z"
        fill="#2EC8CF"
      />
    </g>
    <defs>
      <clipPath id="clip0_347_160">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.444336 0.137695)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>
