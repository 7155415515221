<template>
  <svg class=" text-primary-800 fill-current"
    width="37"
    height="28"
    viewBox="0 0 37 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
    class=" text-primary-800 fill-current"
      d="M1.8335 1.2041H35.1668M1.8335 13.7041H35.1668M1.8335 26.2041H35.1668"
      stroke="#2EC8CF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>