<template>
    <div class="ml-8 hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
        <PopoverGroup as="nav" class="flex space-x-10">
          <!--<Popover class="relative" v-slot="{ open }">
            <PopoverButton :class="[open ? 'text-primary-500' : 'text-white', 'group filter drop-shadow-lg rounded-md inline-flex items-center text-lg font-medium hover:text-primary-500 focus:outline-none ']">
              <span>Solutions</span>
              <ChevronDownIcon :class="[open ? 'text-primary-500' : 'text-white', 'ml-2 h-5 w-5 group-hover:text-white']" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                <div class="rounded-lg shadow-lg  ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid gap-6  px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                    <a v-for="item in solutions" :key="item.name" :href="item.href" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                      <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div class="ml-4">
                        <p class="text-lg font-medium text-primary-500">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-white">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="p-5 bg-gray-50 sm:p-8">
                    <a href="#" class="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">
                      <div class="flex items-center">
                        <div class="text-lg font-medium text-primary-500">Enterprise</div>
                        <span class="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800"> New </span>
                      </div>
                      <p class="mt-1 text-sm text-white">Empower your entire team with even more advanced tools.</p>
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>-->
          <a href="#vision" class="text-lg font-medium text-white hover:text-primary-500 filter drop-shadow-lg" v-smooth-scroll> Vision </a>
          <a href="https://wp.collectiverse.com" target="_blank" class="text-lg font-medium text-white hover:text-primary-500 filter drop-shadow-lg"> White Paper </a>
          <a href="#roadmap" class="text-lg font-medium text-white hover:text-primary-500 filter drop-shadow-lg" v-smooth-scroll> Road Map </a>
          <a href="#team" class="text-lg font-medium text-white hover:text-primary-500 filter drop-shadow-lg" v-smooth-scroll> Team </a>
          <a href="https://merit.collectiverse.com/" target="_blank" class="text-lg font-medium text-white hover:text-primary-500 filter drop-shadow-lg"> Merit </a>
          
        </PopoverGroup>
        <div class="flex items-center space-x-8 md:ml-12">
          <div class=" flex items-center space-x-4">
            <a :href="Social.twitter" target="_blank" v-if="Social.twitter"> <IconTwitter1 /></a>
            <a :href="Social.telegram" target="_blank" v-if="Social.telegram"> <IconTelegram /></a>
            <a :href="Social.discord" target="_blank" v-if="Social.discord"> <IconDiscord /></a>
            <a :href="Social.medium" target="_blank" v-if="Social.medium"> <IconMedium /></a>
            <a :href="Social.github" target="_blank" v-if="Social.github"> <IconGithub /></a>
             
            
          </div>
          <Button btn-style="outlined" @click="goToUrl" ><span>Join Discord</span></Button>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import { auth, storage } from "../../firebase/firebase";
import { useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex';
import { PopoverGroup } from '@headlessui/vue'
import Button from './Button.vue';
import IconTwitter1 from '../Icons/IconTwitter1.vue';
import IconTelegram from '../Icons/IconTelegram.vue';
import IconDiscord from '../Icons/IconDiscord.vue';
import IconMedium from '../Icons/IconMedium.vue';
import IconGithub from '../Icons/IconGithub.vue';
/*import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline'*/
//import { ChevronDownIcon } from '@heroicons/vue/solid'
export default {
  components: {
    IconTwitter1,
    IconTelegram,
    IconDiscord,
    IconMedium,
    IconGithub,
    //Popover, 
    //PopoverButton, 
    PopoverGroup,
    Button, 
    //PopoverPanel,
    //ChevronDownIcon,
    //MenuIcon,
    //XIcon,
  },
  props: ['type'],
  setup(props) {

       const router = useRouter();
       const store = useStore();

        const fullName = computed(() => store.getters['admin/getFullName']);
        
        const email = computed(() => store.getters['admin/getEmail']);

        const avatar = ref('');
        const Social = {
          twitter: 'https://twitter.com/collectiverse_',
          telegram: '',
          discord: 'https://discord.gg/collectiverse',
          medium: '',
          github: ''
        }
      const logout = async () => {
        if (props.type == 'admin') {
            await auth.signOut();
            router.push(`/admin/login`);
        } else {
            router.push(`/`);
        }
      }

      const getAvatar = async () => 
        {
            const image = store.getters['user/getAvatar'];

            if (image) {
                var storageRef = storage.ref();
                const url = await storageRef.child(image).getDownloadURL();
                avatar.value = url;
            }
        };

      const currentAddress = computed(() => {
          if (props.type == 'customer') {
            return store.getters['blockchain/getCurrentAddress'];
          } else {
            return '';
          }
      });

      const goToUrl = () => {
        window.open('https://discord.gg/collectiverse')
      }

      onMounted(async () => {
          await getAvatar();
      })
  
    return {
      goToUrl,
      logout,
      currentAddress,
      fullName,
      avatar,
      email,
      Social
    }
  },
}
</script>