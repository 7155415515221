<template>
  <svg
    width="23"
    height="21"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_296_1110)">
      <path
        d="M7.29 18.7534C14.837 18.7534 18.965 12.5004 18.965 7.0784C18.965 6.9004 18.965 6.7234 18.953 6.5484C19.7562 5.96693 20.4493 5.24701 21 4.4224C20.2511 4.7544 19.4566 4.97216 18.643 5.0684C19.4996 4.55546 20.1408 3.74875 20.447 2.7984C19.6417 3.27628 18.7607 3.61313 17.842 3.7944C17.2234 3.13616 16.405 2.70023 15.5136 2.5541C14.6222 2.40797 13.7075 2.5598 12.9111 2.98607C12.1147 3.41234 11.4811 4.08927 11.1083 4.91206C10.7355 5.73485 10.6444 6.65758 10.849 7.5374C9.21759 7.45564 7.6216 7.03172 6.16465 6.29317C4.70769 5.55461 3.42233 4.51792 2.392 3.2504C1.86732 4.1536 1.70659 5.22282 1.94254 6.24036C2.17848 7.25791 2.79337 8.14728 3.662 8.7274C3.00926 8.7085 2.37063 8.5329 1.8 8.2154V8.2674C1.80039 9.21469 2.1284 10.1327 2.7284 10.8658C3.3284 11.5988 4.16347 12.1018 5.092 12.2894C4.48781 12.4541 3.85389 12.4781 3.239 12.3594C3.50116 13.1749 4.01168 13.888 4.69913 14.399C5.38658 14.91 6.21657 15.1934 7.073 15.2094C6.22212 15.878 5.24779 16.3722 4.20573 16.6639C3.16367 16.9556 2.07432 17.039 1 16.9094C2.8766 18.1137 5.06019 18.7525 7.29 18.7494"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_296_1110"
        x="0"
        y="0.5"
        width="24"
        height="20.2529"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.53 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_296_1110"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_296_1110"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>