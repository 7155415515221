<template>
    <div class="">
        <a href="/">
        <svg width="104" height="103" viewBox="0 0 104 103" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_36_2509)">
            <g opacity="0.12">
            <path opacity="0.43" d="M51.5327 103C79.9935 103 103.065 79.9427 103.065 51.5C103.065 23.0573 79.9935 0 51.5327 0C23.072 0 0 23.0573 0 51.5C0 79.9427 23.072 103 51.5327 103Z" fill="#5FFFFF"/>
            </g>
            <path d="M38.7769 89.1287L13.248 45.0444L27.5611 20.3304L51.5335 61.7259L75.506 20.3304L89.819 45.0453L64.2884 89.1295L51.5326 67.1026L38.7769 89.1287ZM53.089 64.4138L64.2875 83.7511L86.7036 45.0444L75.5051 25.7079L53.089 64.4138ZM16.3626 45.0444L38.7778 83.7511L49.9762 64.4138L27.5611 25.7079L16.3626 45.0444Z" fill="#5FFFFF"/>
            <path d="M86.1668 71.4428H16.8994L51.5335 11.6381L86.1668 71.4428ZM21.5704 68.7549H81.4958L51.5335 17.0164L21.5704 68.7549Z" fill="white"/>
            <path d="M51.5284 64.4124L49.0176 68.7482H54.04L51.5284 64.4124Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_36_2509">
            <rect width="103.065" height="103" fill="white"/>
            </clipPath>
            </defs>
            </svg>

        </a>
    </div>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>